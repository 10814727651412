export default {
    props: {
        value: {},
        type: { type: String, default: 'text'},
        label: { type: String, default: ''},
        placeholder: { type: String, default: ''},
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false},
        autocomplete: { type: String, default: 'On'},
        id: {type: String ,default: null},
        maxlength: {type: Number ,default: null},
        preventSpace: {type: Boolean,default: false}
    },
    data(){
        return {
            fsWidth: 0,
            floatLabel: false,
            isFocus: false
        }
    },
    mounted(){
        this.checkFloatLabel(this._value)
        if(this.placeholder != ''){
            this.addFloatLabel()
        }
    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        _value: {
            get(){
                return this.value
            },
            set(val){
                this.$emit('input', val)
            }
        },
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        }
    },
    watch: {
        _value(val){
            this.checkFloatLabel(val)
            if (this.preventSpace) {
                this._value = val.replace(/\W/g, '')
            }
        }
    },
    methods: {
        onUpdate() {
            this.$emit('update')
        },
        change(){
            this.$emit('change')
        },
        keyup()
        {
            this.$emit('keyup')
        },
        clickLabel() {
            this.$refs.theInput.focus()
        },

        fieldFocus() {
            this.addFloatLabel()
            this.addFocus()
        },
        fieldBlur() {
            if(!this._value && this.placeholder == ''){
                this.removeFloatLabel()
            }
            this.removeFocus()
        },
        checkFloatLabel(val) {
            if(!val && !this.isFocus && this.placeholder == ''){
                this.removeFloatLabel()
            } else {
                this.addFloatLabel()
            }
        },
        addFloatLabel() {
            this.$refs.wrapper.$el.classList.add('float-label')
            setTimeout(()=> {
                if(this.$refs.lbl != undefined)
                {
                    this.fsWidth = this.$refs.lbl.clientWidth
                }

            }, 250)
            this.floatLabel = true
        },
        removeFloatLabel() {
            this.$refs.wrapper.$el.classList.remove('float-label')
            this.floatLabel = false
        },
        addFocus() {
            this.$refs.wrapper.$el.classList.add('focus')
            this.isFocus = true
        },
        removeFocus() {
            this.$refs.wrapper.$el.classList.remove('focus')
            this.isFocus = false
        },
        getClassIndicate(validationContext){
            if(validationContext.validated || validationContext.dirty){
                var IsVal = this._getValidationState(validationContext) ? 'valid' : 'fail'
                return IsVal
            } return ''
        },
        btKeyUp(e) {
            this.$emit('btKeyUp')
            if (this.preventSpace) {
                if (/^\W$/.test(e.key)) {
                    e.preventDefault()
                }
            }
        },
    }
}