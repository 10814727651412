<template>
  <svg width="16" height="16" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.09515 16.5868V0H2.99052C1.36309 0 0.0390625 1.32403 0.0390625 2.95146V17.524C0.715182 16.9407 1.59444 16.5868 2.55536 16.5868H4.09515V16.5868Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M17.878 11.4528C18.023 11.4528 18.1669 11.4581 18.3096 11.4677V0H5.44043V16.5868H11.5833C12.1826 13.6607 14.7773 11.4528 17.878 11.4528V11.4528ZM9.52293 3.96081H14.025V5.30599H9.52293V3.96081ZM8.00219 6.88118H15.5457V8.22636H8.00219V6.88118Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M12.3313 21.1175H3.4478V19.7723H11.738C11.5538 19.1767 11.4541 18.5446 11.453 17.8896H2.55525C1.14627 17.8896 0 19.0359 0 20.4448C0 21.8538 1.14627 23.0001 2.55525 23.0001H14.0039C13.3305 22.4896 12.7606 21.8496 12.3313 21.1175V21.1175Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M17.8776 12.7554C15.0532 12.7554 12.7554 15.0532 12.7554 17.8776C12.7554 20.702 15.0532 22.9998 17.8776 22.9998C20.702 22.9998 22.9998 20.702 22.9998 17.8776C22.9998 15.0532 20.702 12.7554 17.8776 12.7554V12.7554ZM17.8771 14.8111C18.2549 14.8111 18.5346 15.1198 18.551 15.4849C18.5673 15.8488 18.2296 16.1587 17.8771 16.1587C17.4994 16.1587 17.2197 15.85 17.2033 15.4849C17.187 15.121 17.5247 14.8111 17.8771 14.8111ZM18.5525 20.5037H17.2048V17.0538H18.5525V20.5037Z"
      :fill="hovered ? hoverColor : fillColor" />
  </svg>
</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>