import proCommunicationServices from '../../../../services/provider/recall'
import supCommunicationServices from '../../../../services/supplier/supplier-recall'
import notiCommunicationServices from '../../../../services/notisphere/recall'
import delegateService from '../../../../services/provider/delegates'
import recallNoteService from '../../../../services/provider/recall-notes'
import locationpreprocessorService from '../../../../services/notisphere/locationpreprocessor'
import  communicationNoticeService from '../../../../services/provider/communicationNotices'
import {FILEPREVIEW } from '../../../../misc/commons'
export default {
    props: {
        docId: {type: Number, default: null},
        delegateId: {type: Number, default: null},
        isSupplier: {type: Boolean, default: false},
        isProvider: {type: Boolean, default: false},
        isNotisphere: {type: Boolean, default: false},
        showView: {type: Boolean, default: false},
        showPreView: {type: Boolean, default: false},
        orgId: {type: Number, default: null},
        isDelegate: {type: Boolean, default: false},
        isRecallNotice: {type: Boolean, default: false},
        isproviderRecallNote: {type: Boolean, default: false},
        isNotispherePreProcessorFile: {type: Boolean, default: false},
        locationprocessorFileId: {type: String, default: null},
        showDelete: {type: Boolean,default: false}
    },
    data() {
        return {
            url: require('@/assets/img/notisphere-blue.png'),
            item: null,
        }
    },
    computed: {
        fileType(){
            if (this.item.type && this.item.type.split('/')[0] == 'image') {
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                return 'image'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'video') {
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                return 'video'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && this.item.type.split('/')[1] == 'pdf') {
                this.showPreView = true
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                return 'pdf'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && (this.item.type.split('/')[1] == FILEPREVIEW.SPREADSHEET|| this.item.type.split('/')[1] == 'vnd.ms-excel')) {
                this.showPreView = true
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'xlsx'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'text' && this.item.type.split('/')[1] == 'csv') {
                this.showPreView = true
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                return 'csv'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'text' && this.item.type.split('/')[1] == 'plain') {
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                return 'text'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && (this.item.type.split('/')[1] == 'msword' || this.item.type.split('/')[1] == FILEPREVIEW.DOCUMENT)) {
                this.showPreView = true
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'doc'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && (this.item.type.split('/')[1] == FILEPREVIEW.PPT || this.item.type.split('/')[1] == FILEPREVIEW.DOCUMENT)) {
                this.showPreView = true
                this.item.name = this.item.name.replace(this.item.extension.trim(),'')
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'ppt'
            }
            else {
                return null
            }
        }
    },
    methods: {
        forceRerenderView() {
            this.getDocumentById().then(()=>{
                this.$refs.modal.show()
            })
        },
        deleteDocument() {
            this._showConfirm('Are you sure you want to delete the document?').then(confirm => {
                if (confirm) {
                    proCommunicationServices.deleteDocumentById(this.docId).then(res => {
                        this._showToast('Document deleted successfully.')
                        this.$emit('reLoad')
                    })
                }
            })
        },
        download() {
            this.getDocumentById().then(resp=>{
                if (this.item && this.item.signed_url) {
                    this.openExternalLink(this.item.signed_url)
                } else {
                    this._showToast('Nothing to download', {variant: 'danger'})
                }
            })
        },
        async forceRerenderDownload() {
            await this.getDocumentById()
            this.download()
        },
        getDocumentById() {
            return new Promise ((resolve, reject) => {
                if(this.isProvider == true) {
                    proCommunicationServices.getDocumentById(this.docId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                if(this.isDelegate == true) {
                    if(this.$route.query.m == 1)
                    {
                        delegateService.getDelegateDocumentById(this.docId, this.orgId,this.$route.query.h).then(resp => {
                            if (!resp.error) {
                                this.item = resp.data.d
                                resolve()
                            } else {
                                reject()
                            }
                        })
                    }
                    else
                    {
                        delegateService.getDocumentById(this.docId, this.orgId).then(resp => {
                            if (!resp.error) {
                                this.item = resp.data.d
                                resolve()
                            } else {
                                reject()
                            }
                        })
                    }

                }
                if(this.isRecallNotice == true) {
                    communicationNoticeService.document(this.orgId, this.docId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                if(this.isSupplier == true) {
                    supCommunicationServices.getDocumentById(this.docId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                if(this.isNotisphere == true) {
                    notiCommunicationServices.getDocumentById(this.docId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                if(this.isproviderRecallNote == true) {
                    recallNoteService.getDocumentById(this.docId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                if(this.isNotispherePreProcessorFile == true) {
                    locationpreprocessorService.getDocumentById(this.locationprocessorFileId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
            })
        }
    }
}