<template>
  <div id="core-view">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
export default {
    metaInfo () {
        return {
        }
    }
}
</script>
