export default {
    props: {
        value: { type: String, default: null },
        rows: { type: Number, default: 3},
        maxRows: { type: Number, default: 6},
        label: { type: String, default: '' },
        placeholder: { type: String, default: ''},
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false}
    },
    data () {
        return {
            isFocus: false,
            floatLabel: false,
            fsWidth: 0,
        }
    },
    mounted() {
        this.checkFocus(this.value)
    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        _value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        }
    },
    watch: {
        value(v) {
            this.checkFocus(v)
        }
    },
    methods: {
        //label
        labelClick() {
            this.$refs.textArea.focus()
        },

        checkFocus(val) {
            if(!val){
                if(!this.isFocus) {
                    this.$refs.wrapper.classList.remove('onFocus')
                }
                this.floatLabel = false
            } else {
                this.$refs.wrapper.classList.add('onFocus')
                setTimeout(()=> {
                    this.fsWidth = this.$refs.theLabel.clientWidth
                }, 250)
                this.floatLabel = true
            }
        },
        hoverLabel() {
            this.isFocus = true
            this.$refs.wrapper.classList.add('onFocus')
        },
        leaveLabel() {
            if (!this.value || this.value == '') {
                this.isFocus = false
                this.$refs.wrapper.classList.remove('onFocus')
            }
        },
        getClassIndicate(validationContext){
            if(validationContext.validated || validationContext.dirty){
                var IsVal = this._getValidationState(validationContext) ? 'valid' : 'fail'
                return IsVal
            } return ''
        },
    }
}