import { AUTH_ACTIONS } from '../../../store/actions'
import authServices from '../../../services/auth'
import quickLinkService from '../../../services/notisphere/quick-link'
import { paths } from '../../../router/paths'
import { getDefaultRoute } from '../../../router'
import { getUserType,getAccessToken ,getRefreshToken} from '../../../misc/auth'
import authService from '../../../services/auth'
import 'cross-fetch/polyfill'
import _ from 'lodash'
import decode from 'jwt-decode'
import conf from '../../../config'
export let IsRememberDevice = `${conf.REMEMBER_DEVICE}`
import {FilterConnections_Key} from '../../../misc/tableHeaders'

export default {
    data() {
        return {
            SAVE_FILTER_CONNECTIONS_Array: {
                SavedProviderConnections: {
                    Page_Recalls: {
                        supplierOrgName: null,
                        recallName: null,
                        siteName: null,
                        category: null
                    }
                }
                ,SavedSupplierConnections: {
                    Page_Recalls: {
                        status: null
                    },
                    Page_Recalls_AffectedLocations: {
                        status: null
                    }
                },
                SavedNotisphereConnections: {
                    Page_Recalls: {
                        status: null,
                        supplierOrgId: null
                    },
                    Page_LocationPreProcessor: {
                        status: null,
                        supplierName: null
                    }
                },
                user_id: null
            },
            recallIconHovered: false,
            disruptionIconHovered: false,
            filesIconHovered: false,
            locationsIconHovered: false,
            learnMoreIconHovered: false,
            erResponsesIconHovered: false,
            locationsPreProcessorIconHovered: false,
            quickLinksIconHovered: false,
            manageSupplierMasterIconHovered: false,
            sitesIconHovered: false,
            CommunicationType: '',
            SupplierName: '',
            ProviderName: '',
            classChange: '',
            AccountAdministratorFirstName: '',
            AccountAdministratorLastName: '',
            AccountAdministratorEmailAddress: '',
            active: false,
            Useractive: '',
            intervalId: '',
            events: ['load', 'mousemove', 'mousedown','click', 'scroll', 'keypress'],
            ActivityTimeMinutes: 15,
            ExpTimeMinutes: 5,
            IsQuicklinkVisible: false,
            isSidebarOpen: true,
            btnActionType: null,
            pagewrapper: 'page-wrapper',
            accountLinks: [],
            items: [
                //Notisphere
                {
                    to: paths.noti_Dashboard.path,
                    text: 'Dashboard',
                    icon: 'fas fa-tachometer-alt',
                    isAllowed: this._checkPermissions(['Notisphere_ViewDashboard'])
                },
                {
                    to: paths.noti_Key_Metrics.path,
                    text: 'Key Metrics',
                    icon: 'far fa-chart-bar',
                    isAllowed: this._checkPermissions(['Notisphere_ViewKeyMetrics'])
                },
                {
                    to: paths.noti_Recall.path,
                    text: 'Recalls',
                    icon: 'fas fa-envelope',
                    isAllowed: this._checkPermissions(['Notisphere_ManageRecalls'])
                },

                {
                    to: paths.noti_er_Responses.path,
                    text: 'eR Responses',
                    icon: 'ERRESPONSESVG',
                    isAllowed: this._checkPermissions(['NotiSphere_ResponderRole'])
                },
                {
                    to: paths.noti_Supplier.path,
                    text: 'Suppliers',
                    icon: 'fas fa-truck',
                    isAllowed: this._checkPermissions(['Notisphere_ManageSuppliers'])
                },
                {
                    to: paths.noti_Provider.path,
                    text: 'Providers',
                    icon: 'fas fa-boxes',
                    isAllowed: this._checkPermissions(['Notisphere_ManageProviders'])
                },
                {
                    to: paths.noti_Location_preProcessor.path,
                    text: 'Location Pre-Processor',
                    icon: 'LOCPREPROSVG',
                    isAllowed: this._checkPermissions(['Notisphere_ManageLocations'])
                },
                {
                    to: paths.noti_Location.path,
                    text: 'Locations',
                    icon: 'fas fa-search-location',
                    isAllowed: this._checkPermissions(['Notisphere_ManageLocations'])
                },

                {
                    to: paths.noti_LocationVerification.path,
                    text: 'Locations Verification',
                    icon: 'fas fa-address-card',
                    isAllowed: this._checkPermissions(['Notisphere_ManageLocations'])
                },
                {
                    to: paths.noti_ProductField.path,
                    text: 'Product Fields',
                    icon: 'fas fa-box-open',
                    isAllowed: this._checkPermissions(['Notisphere_ManageProductFields'])
                },
                {
                    to: paths.noti_LookupValues.path,
                    text: 'Lookup Values',
                    icon: 'far fa-eye',
                    isAllowed: this._checkPermissions(['Notisphere_ManageLookupValues'])
                },
                {
                    to: paths.noti_User.path,
                    text: 'Users',
                    icon: 'fas fa-user',
                    isAllowed: this._checkPermissions(['Notisphere_ManageUsers'])
                },
                {
                    to: paths.noti_Role.path,
                    text: 'Roles',
                    icon: 'fas fa-shield-alt',
                    isAllowed: this._checkPermissions(['Notisphere_ManageRoles'])
                },
                {
                    to: paths.noti_QuickLinks.path,
                    text: 'Quick Links',
                    icon: 'QUICKLINKSVG',
                    isAllowed: this._checkAllPermissions()
                },
                {
                    to: paths.noti_EmailTemplate.path,
                    text: 'Email Templates',
                    icon: 'fas fa-mail-bulk',
                    isAllowed: this._checkPermissions(['Notisphere_ManageEmailTemplates'])
                },
                {
                    to: paths.noti_ContentManagement.path,
                    text: 'Content Management',
                    icon: 'far fa-newspaper',
                    isAllowed: this._checkPermissions(['Notisphere_ManageEmailTemplates'])
                },
                {
                    to: paths.noti_manage_supplier_master.path,
                    text: 'Manage Supplier Master',
                    icon: 'GUIDESVG',
                    isAllowed: this._checkPermissions(['Notisphere_ManageUserGuides'])
                },
                {
                    to: paths.noti_open_fda.path,
                    text: 'FDA',
                    icon: 'fas fa-hospital-user',
                    isAllowed: this._checkPermissions(['Notisphere_ViewFDAInformation'])
                },
                // {
                //     to: paths.noti_manage_user_guides.path,
                //     text: 'Manage User Guides',
                //     icon: 'GUIDESVG',
                //     isAllowed: this._checkPermissions(['Notisphere_ManageUserGuides'])
                // },
                // {
                //     to: paths.noti_FAQ.path,
                //     text: 'Manage FAQs',
                //     icon: 'fas fa-question-circle',
                //     isAllowed: this._checkPermissions(['Notisphere_ManageFAQs'])
                // },

                //Supplier
                {
                    to: paths.supp_Dashboard.path,
                    text: 'Dashboard',
                    icon: 'fas fa-tachometer-alt',
                    isAllowed: this._checkPermissions(['Supplier_ViewDashboard'])
                },
                {
                    to: paths.supp_Recall.path,
                    text: 'Recalls',
                    icon: 'fa fa-envelope',
                    isAllowed: this._checkPermissions(['Supplier_ManageRecalls'])
                },
                {
                    to: paths.supp_ProdDisruption.path,
                    text: 'Supply Advisories',
                    icon: 'fas fa-store-slash',
                    isAllowed: this._checkPermissions(['Supplier_ManageDisruption'])
                },
                {
                    to: null,
                    text: 'Organization Settings',
                    icon: 'fas fa-sitemap',
                    isAllowed: this._checkPermissions(['Supplier_ManageLookupValues', 'Supplier_ManageProductFields', 'Supplier_ManageProfile']),
                    visible: false,
                    child: [
                        {
                            to: paths.supp_Organization.path,
                            text: 'General',
                            icon: 'fas fa-archway',
                            isAllowed: this._checkPermissions(['Supplier_ManageProfile'])
                        },
                        {
                            to: paths.supp_Role.path,
                            text: 'Roles',
                            icon: 'fas fa-shield-alt',
                            isAllowed: this._checkPermissions(['Supplier_ManageRoles'])
                        },
                        {
                            to: paths.supp_User.path,
                            text: 'Users',
                            icon: 'fas fa-user',
                            isAllowed: this._checkPermissions(['Supplier_ManageUsers'])
                        },
                        {
                            to: paths.supp_Sales_Rep.path,
                            text: 'Sales Reps',
                            icon: 'fas fa-users',
                            isAllowed: this._checkPermissions(['Supplier_ManageSalesRepresentative'])
                        }
                    ]
                },
                {
                    to: null,
                    text: 'Recall Settings',
                    icon: 'RECALLSETTINGS',
                    isAllowed: this._checkPermissions(['Supplier_ManageRecalls']),
                    visible: false,
                    child: [
                        {
                            to: paths.supp_Internal_Actions.path,
                            text: 'Template Actions',
                            icon: 'fas fa-directions',
                            isAllowed: this._checkPermissions(['Supplier_ManageRecalls'])
                        },
                        {
                            to: paths.supp_Distribution_List.path,
                            text: 'Distribution Lists',
                            icon: 'fas fa-clipboard-list',
                            isAllowed: this._checkPermissions(['Supplier_ManageRecalls'])
                        },
                    ]
                },
                {
                    to: null,
                    text: 'Advisory Settings',
                    icon: 'disruption',
                    isAllowed: this._checkPermissions(['Supplier_ManageDisruption']),
                    visible: false,
                    child: [
                        {
                            to: paths.supp_Internal_Actions_Pd.path,
                            text: 'Template Actions',
                            icon: 'fas fa-directions',
                            isAllowed: this._checkPermissions(['Supplier_ManageDisruption'])
                        },
                        {
                            to: paths.supp_Distribution_Lists_Pd.path,
                            text: 'Distribution Lists',
                            icon: 'fas fa-clipboard-list',
                            isAllowed: this._checkPermissions(['Supplier_ManageDisruption'])
                        },
                    ]
                },
                {
                    to: null,
                    text: 'Location Management',
                    icon: 'fas fa-map-marked-alt',
                    isAllowed: this._checkPermissions(['Supplier_ManageLocations']),
                    visible: false,
                    child: [
                        {
                            to: paths.supp_Provider.path,
                            text: 'Providers',
                            icon: 'fas fa-boxes',
                            isAllowed: this._checkPermissions(['Supplier_ManageLocations'])
                        },
                        {
                            to: paths.supp_Location.path,
                            text: 'Locations',
                            icon: 'LOCSVG',
                            isAllowed: this._checkPermissions(['Supplier_ManageLocations'])
                        },
                        {
                            to: paths.supp_LocationFiles.path,
                            text: 'Files',
                            icon: 'SVG',
                            isAllowed: this._checkPermissions(['Supplier_ManageLocations'])
                        }
                    ]
                },
                {
                    to: null,
                    text: 'Help',
                    icon: 'fas fa-life-ring',
                    isAllowed: this._checkPermissions(['Supplier_ViewFAQs', 'Supplier_ViewContact', 'Supplier_ViewUserGuides']),
                    visible: false,
                    child: [
                        {
                            to: paths.userGuide.path,
                            text: 'Learn More',
                            icon: 'GUIDESVG',
                            isAllowed: this._checkPermissions(['Supplier_ViewUserGuides'])
                        },
                        {
                            to: paths.faq.path,
                            text: 'FAQs',
                            icon: 'fas fa-question-circle',
                            isAllowed: this._checkPermissions(['Supplier_ViewFAQs'])
                        },
                        {
                            to: paths.contact.path,
                            text: 'Contact Us',
                            icon: 'fas fa-address-book',
                            isAllowed: this._checkPermissions(['Supplier_ViewContact'])
                        }
                    ]
                },

                //provider
                {
                    to: paths.prov_Dashboard.path,
                    text: 'Dashboard',
                    icon: 'fas fa-tachometer-alt',
                    isAllowed: this._checkPermissions(['Provider_ViewDashboard'])

                },
                {
                    to: paths.prov_Recall.path,
                    text: 'Recalls',
                    icon: 'fas fa-envelope',
                    isAllowed: this._checkPermissions(['Provider_ManageRecalls'])
                },
                {
                    to: paths.prov_ProdDisruption.path,
                    text: 'Supply Advisories',
                    icon: 'fas fa-store-slash',
                    isAllowed: this._checkPermissions(['Provider_ManageDisruption'])
                },
                {
                    to: paths.er_Responses.path,
                    text: 'eR Responses',
                    icon: 'ERRESPONSESVG',
                    isAllowed: this._checkPermissions(['Provider_ResponderRole'])
                },
                {
                    to: paths.prov_ProductDisruption_AccessDenied_ViewItem.path,
                    text: 'Access Denied',
                    icon: 'ACCESSDENIED',
                    isAllowed: this._checkPermissions(['Provider_ResponderRole'])
                },
                {
                    to: null,
                    text: 'Organization Settings',
                    icon: 'fas fa-sitemap',
                    isAllowed: this._checkPermissions(['Provider_ManageProfile', 'Provider_ManageLocations', 'Provider_ManageRoles', 'Provider_ManageUsers','Provider_ManageSupplierContacts','Provider_ManageEmailTemplates']),
                    visible: false,
                    child: [
                        {
                            to: paths.prov_Organization.path,
                            text: 'General',
                            icon: 'fas fa-archway',
                            isAllowed: this._checkPermissions(['Provider_ManageProfile'])
                        },
                        {
                            to: paths.prov_LocationVerification.path,
                            text: 'Locations Verification',
                            icon: 'fas fa-address-card',
                            isAllowed: this._checkPermissions(['Provider_ManageLocations'])
                        },
                        {
                            to: paths.prov_Site.path,
                            text: 'Sites',
                            icon: 'SITESSVG',
                            isAllowed: this._checkPermissions(['Provider_ManageLocations'])
                        },
                        {
                            to: paths.prov_Role.path,
                            text: 'Roles',
                            icon: 'fas fa-user-cog',
                            isAllowed: this._checkPermissions(['Provider_ManageRoles'])
                        },
                        {
                            to: paths.prov_User.path,
                            text: 'Users',
                            icon: 'fas fa-user-friends',
                            isAllowed: this._checkPermissions(['Provider_ManageUsers'])
                        },
                        {
                            to: paths.prov_SupplierContacts_List.path,
                            text: 'Supplier Contacts',
                            icon: 'fas fa-user-friends',
                            isAllowed: this._checkPermissions(['Provider_ManageSupplierContacts'])
                        },
                        {
                            to: paths.prov_EmailTemplate.path,
                            text: 'Email Templates',
                            icon: 'fas fa-mail-bulk',
                            isAllowed: this._checkPermissions(['Provider_ManageEmailTemplates'])
                        },
                    ]
                },
                {
                    to: null,
                    text: 'Recall Settings',
                    icon: 'RECALLSETTINGS',
                    isAllowed: this._checkPermissions(['Provider_ManageRecalls']),
                    visible: false,
                    child: [
                        {
                            to: paths.prov_Internal_Actions.path,
                            text: 'Template Actions',
                            icon: 'fas fa-directions',
                            isAllowed: this._checkPermissions(['Provider_ManageRecalls'])
                        },
                        {
                            to: paths.prov_Distribution_List.path,
                            text: 'Distribution Lists',
                            icon: 'fas fa-clipboard-list',
                            isAllowed: this._checkPermissions(['Provider_ManageRecalls'])
                        },

                    ]
                },
                {
                    to: null,
                    text: 'Help',
                    icon: 'fas fa-life-ring',
                    isAllowed: this._checkPermissions(['Provider_ViewFAQs', 'Provider_ViewContact', 'Provider_ViewUserGuides']),
                    visible: false,
                    child: [
                        {
                            to: paths.userGuide.path,
                            text: 'Learn More',
                            icon: 'GUIDESVG',
                            isAllowed: this._checkPermissions(['Provider_ViewUserGuides'])
                        },
                        {
                            to: paths.faq.path,
                            text: 'FAQs',
                            icon: 'fas fa-question-circle',
                            isAllowed: this._checkPermissions(['Provider_ViewFAQs'])
                        },
                        {
                            to: paths.contact.path,
                            text: 'Contact Us',
                            icon: 'fas fa-address-book',
                            isAllowed: this._checkPermissions(['Provider_ViewContact'])
                        }
                    ]
                },
            ]
        }
    },

    computed: {
        permissionDenied(){
            return (localStorage.getItem('access') == 'denied' || this.$store.state.isPermissionDenied)
        },
        permissionErrorMessage(){
            return this.$store?.state?.permissionErrorMessage
        },
        permissionDeniedErrorMessage(){
            if(this.$store.state.userType == 'PROVIDER')
            {
                this.classChange = 'Pro'
                return 'You have received a ' + (this.CommunicationType == 'RECALL' ? 'Recall' : 'Supply Advisory') + ' from ' + this.SupplierName + ' but you do not currently have permissions to view the event details within'
                + ' the ' + this.ProviderName + ' NotiSphere account. Please click the button below to request permission from your NotiSphere Administrator ' + this.AccountAdministratorFirstName + ' '
                +  this.AccountAdministratorLastName + ' at ' + this.AccountAdministratorEmailAddress + '.'
            }
            else
            {
                this.classChange = 'Sup'
                return 'You don’t have permission to access.'
            }
        },
        checkStore() {
            if (this.$store.state.user == '' || this.$store.state.user == undefined) {
                alert('user store become null , need to set it again')
            }
        },
        user() {
            if(this.$store.state.user!='')
            {
                if(this.$store.state.CustomizedHeaders.length==1 && this.$store.state.CustomizedHeaders[0].userId==null)
                {
                    this.$store.state.CustomizedHeaders[0].userId=this.$store.state.user.id
                }
                else{
                    var filterdata=this.$store.state.CustomizedHeaders.filter(s=>s.userId==this.$store.state.user.id)
                    if(filterdata.length==0)
                    {
                        var Table_Header_Customization={
                            userId: this.$store.state.user.id,
                            Notisphere_Login: {
                                Recall_Headers: [],
                                Supplier_Headers: [],
                                Provider_Headers: [],
                                OpenFDA_Recall_Headers: [],
                            },
                            Supplier_Login: {
                                Recall_Headers: [],
                                Recall_AffectedLocation_Headers: [],
                                Recall_AffectedCustomer_Headers: [],
                                Disruption_Headers: [],
                            },
                            Provider_Login: {
                                Recall_Headers: [],
                                Disruption_Headers: [],
                            },
                        }
                        this.$store.state.CustomizedHeaders.push(Table_Header_Customization)
                    }
                }
            }
            if(this.$store.state.user!='')
            {
                var localdata=JSON.parse(localStorage.getItem(FilterConnections_Key))?.filter(s=>s.user_id==this.$store.state.user.id)[0]
                if(localdata==undefined)
                {
                    var filterarray=[]
                    this.SAVE_FILTER_CONNECTIONS_Array.user_id=this.$store.state.user.id
                    filterarray.push(this.SAVE_FILTER_CONNECTIONS_Array)
                    localStorage.setItem(FilterConnections_Key,JSON.stringify(filterarray))
                }
                if(this.$store.state.SavedAllFilterConnections.length==1 && this.$store.state.SavedAllFilterConnections[0].user_id==null)
                {
                    this.$store.state.SavedAllFilterConnections[0].user_id=this.$store.state.user.id
                }
                else{
                    var SavedAllFilterdata=this.$store.state.SavedAllFilterConnections.filter(s=>s.user_id==this.$store.state.user.id)
                    if(SavedAllFilterdata.length==0)
                    {
                        this.SAVE_FILTER_CONNECTIONS_Array.user_id=this.$store.state.user.id
                        this.$store.state.SavedAllFilterConnections.push(this.SAVE_FILTER_CONNECTIONS_Array)
                    }
                }
            }
            return this.$store.state.user
        },
        userPhoto() {
            return _.isObject(this.$store.state.user) && this.$store.state.user.photo ? this.$store.state.user.photo : null
        },
        org() {
            return this.$store.state.organization
        },
        orgPhoto() {
            return _.isObject(this.$store.state.organization) && this.$store.state.organization.profile_photo ? this.$store.state.organization.profile_photo : null
        },
        crumbs: function () {
            var access_token = getAccessToken()
            if (access_token == undefined) {
                this.logout()
            }
            let pathArray = this.$route.path.split('/')
            pathArray.shift()
            var matched = this.$route.matched
            var defaultRoute = getDefaultRoute()
            var breadcrumbs = []
            if (matched[0] && matched[0].path == defaultRoute) {
                breadcrumbs.push({
                    to: null,
                    text: 'Home'
                })
                this.active = false
            } else {
                breadcrumbs.push({
                    to: defaultRoute,
                    text: 'Home'
                })
                if(pathArray[0] == 'provider' && pathArray[1] == 'product-disruption')
                {
                    this.active = true
                }
                pathArray.forEach((el, idx) => {
                    var fullPath = ''
                    for (let j = 0; j <= idx; j++) {
                        fullPath += '/' + pathArray[j]
                    }
                    for (const prop in paths) {
                        let pathWithoutId = paths[prop].path.replace(/\/:id$/, '')
                        if (pathWithoutId === '/provider/recalls/view/:id/:providerCommunicationId')
                        {
                            pathWithoutId = '/provider/recalls/view'
                        }
                        if (pathWithoutId == fullPath) {
                            breadcrumbs.push({
                                to: fullPath,
                                text: paths[prop].meta.breadCrumb
                            })
                            this.active = false
                        }
                    }
                })
            }
            breadcrumbs[breadcrumbs.length - 1].to = null
            return breadcrumbs
        },
    },

    created() {
        console.log(this.crumbs)
        var checkkeyexist=localStorage.getItem('filter_connection')
        if(checkkeyexist!=null || checkkeyexist!=undefined)
        {
            localStorage.removeItem('filter_connection')
        }

        window.addEventListener('resize', this.screenResizeHandler)
        if (getUserType() == 'PROVIDER' || getUserType() == 'SUPPLIER') {
            this.getAccountLinks()
        }
        this.pagewrapper = (localStorage.getItem('access') == 'denied' || this.$store.state.isPermissionDenied) ? this.pagewrapper + ' blurry' : this.pagewrapper
    },

    destroyed() {
        window.removeEventListener('resize', this.screenResizeHandler)
    },

    mounted() {
        if(this.permissionDenied)
        {
            this.getCommunicationDetails()
        }
        this.intervalId=  window.setInterval(() => {
            this.ResetRefreshToken()
        }, 10000)
        this.screenResizeHandler()
        this.pagewrapper = (localStorage.getItem('access') == 'denied' || this.$store.state.isPermissionDenied) ? this.pagewrapper + ' blurry' : this.pagewrapper
    },

    watch: {
        isSidebarOpen() {
            if (this.btnActionType == 'mobile') {
                this.$refs.mainWrapper.classList.toggle('slide-nav')
                this.$refs.sidebar.classList.toggle('opened')
                this.$refs.overlay.classList.toggle('show')
            } else {
                this.$refs.mainWrapper.classList.toggle('mini-sidebar')
            }
            this.btnActionType = null
        }
    },
    methods: {
        hideModal(){
            this.$store.state.isPermissionDenied = false
            let id = localStorage.getItem('id')
            let proId = localStorage.getItem('providerCommunicationId')
            localStorage.removeItem('access')
            localStorage.removeItem('id')
            localStorage.removeItem('providerCommunicationId')
            localStorage.setItem('access','sent')
            authServices.requestAccess(id, proId).then((res) => {
                this._showToast('Your request has been sent.')
                this.$router.push({name: 'Login'})
                window.location.reload(true)
            })
        },
        accessModal(){
            this.$store.state.isPermissionDenied = false
            localStorage.removeItem('access')
            localStorage.removeItem('id')
            localStorage.removeItem('providerCommunicationId')
            this.pagewrapper = (localStorage.getItem('access') == 'denied' || this.$store.state.isPermissionDenied) ? this.pagewrapper + ' blurry' : this.pagewrapper.replaceAll('blurry','')
            window.setInterval(() => {
                window.location.reload(true)
            }, 1000)
            this.$router.push({name: 'Login'})
        },
        getCommunicationDetails(){
            let id = localStorage.getItem('id')
            let proId = localStorage.getItem('providerCommunicationId')
            if(id)
            {
                if(id != 'undefined' && proId != 'undefined')
                {
                    authServices.getCommunicationDetails(id,proId).then((res) => {
                        this.description = res.data
                        if(res.data.d != undefined)
                        {
                            this.CommunicationType = res.data.d.type
                            this.SupplierName = res.data.d.supplier
                            this.ProviderName = res.data.d.provider
                            this.AccountAdministratorFirstName = res.data.d.first
                            this.AccountAdministratorLastName = res.data.d.last
                            this.AccountAdministratorEmailAddress = res.data.d.email
                        }
                    })
                }
            }
        },
        resetTimeout()
        {
            this.Useractive=true
        },
        ResetRefreshToken() {
            var access_token = getAccessToken()
            if (access_token) {
                const decodedAccessToken = this.decodeJwt(access_token)
                if (!decodedAccessToken.exp) { return undefined }
                var expdate = new Date(decodedAccessToken.exp * 1000)
                var Before5minDate = new Date(expdate.getTime() - this.ExpTimeMinutes * 60000)
                var BeforetenminDate = new Date(expdate.getTime() - this.ActivityTimeMinutes * 60000)
                if (BeforetenminDate <= new Date()) {
                    this.events.forEach((event) => {
                        window.addEventListener(event, this.resetTimeout)
                    })
                }
                else{
                    this.Useractive=''
                }
                if (this.Useractive==true && Before5minDate <= new Date() && new Date() < expdate) {
                    this.$store.dispatch(AUTH_ACTIONS.REFRESHTOKEN, {
                        refresh_token: getRefreshToken(),
                        access_token: access_token
                    }).then(resp => {
                        this.Useractive=''
                    })
                }
            }
            if (access_token == undefined) {
                window.clearInterval(this.intervalId)
                if(this.$route.fullPath!='/login')
                {
                    this.logout()
                }
            }
        },
        decodeJwt(encoded) {
            try {
                return decode(encoded)
            } catch (e) {
                return {}
            }
        },
        overlayClick() {
            this.btnActionType = 'mobile'
            this.isSidebarOpen = !this.isSidebarOpen
        },
        sidebarToggle(type) {
            this.btnActionType = type
            this.isSidebarOpen = !this.isSidebarOpen
        },
        clearCookie(cookieName) {
            // Set the cookie's expiration date to a past date
            document.cookie = `${cookieName}=; expires=Fri, 19 Jan 2024 00:00:00 UTC; path=/;domain=qa-notisphere.auth.us-east-2.amazoncognito.com`
        },
        async logout() {
            //document.cookie ='cognito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
            this.clearCookie('cognito')
            console.clear()
            localStorage.removeItem('access')
            localStorage.removeItem('id')
            localStorage.removeItem('providerCommunicationId')
            this.$store.state.isPermissionDenied = false
            let IsSSO = this.$store.state.organization == null ? false : this.$store.state.organization.isSSO == null ? false: this.$store.state.organization.isSSO
            var checkkeyexist=localStorage.getItem('filter_connection')
            if(checkkeyexist!=null || checkkeyexist!=undefined)
            {
                localStorage.removeItem('filter_connection')
            }

            this.$store.dispatch(AUTH_ACTIONS.LOGOUT,'/login')
            if (this.$route.fullPath !== '/login')
                this.$router.push({ name: 'Login' })
            if(IsRememberDevice == 0)
            {
                let arr = ['243A6021-47A0-4912-A101-C4AB08C053C8','1E890D44-A048-4A69-A951-455D739FF8D4','35FF6F68-0A95-40FB-BB54-895C484E9F38','39C7744F-41FD-4EEE-94CA-322331EB046A','app-version']
                for (var j = 0; j<localStorage.length; j++) {
                    for (var i = 0; i<localStorage.length; i++) {

                        if(!arr.includes(localStorage.key(i)))
                        {
                            localStorage.removeItem(localStorage.key(i))

                        }
                    }
                }
            }
            if(IsSSO)
            {
                await authService.IDPLogout().then(res => {
                    window.location.href = res.data.d.SSOUrl + '/logout?response_type=code&client_id=' + res.data.d.AppClientId + '&logout_uri=' + res.data.d.RedirectUrl
                })
            }
            //https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=http://localhost:8080/login&redirect_uri=http://localhost:8080/login
            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=http://localhost:8080/login&redirect_uri=http://localhost:8080/login'
            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=http://localhost:8080/login&redirect_uri=http://localhost:8080/login'
            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=https%3A%2F%2Fqa.notispherealerts.com%2Flogin&redirect_uri=https%3A%2F%2Fqa.notispherealerts.com%2Flogin'
            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=https://qa.notispherealerts.com/login&redirect_uri=https://qa.notispherealerts.com/login'

            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=https%3A%2F%2Fqa.notispherealerts.com%2Flogin'
            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&redirect_uri=https%3A%2F%2Fqa.notispherealerts.com%2Flogin'
            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7h4eem7ii00dmhheqm195au4l4&logout_uri=https://qa.notispherealerts.com/login'
        },
        screenResizeHandler() {
            var sidebarHeight = this.$refs.sidebar.clientHeight
            this.$refs.sidebarWrapper.style.height = sidebarHeight + 'px'
        },
        mouseOver(menuName){
            if(menuName=='Recall Settings'){
                this.recallIconHovered = true
            } else if(menuName=='Advisory Settings'){
                this.disruptionIconHovered = true
            } else if(menuName=='Files'){
                this.filesIconHovered = true
            } else if(menuName=='Locations'){
                this.locationsIconHovered = true
            } else if(menuName=='Learn More'){
                this.learnMoreIconHovered = true
            } else if(menuName=='eR Responses'){
                this.erResponsesIconHovered = true
            } else if (menuName == 'Location Pre-Processor') {
                this.locationsPreProcessorIconHovered = true
            } else if (menuName == 'Quick Links') {
                this.quickLinksIconHovered = true
            } else if (menuName == 'Manage Supplier Master') {
                this.manageSupplierMasterIconHovered = true
            } else if (menuName == 'Sites') {
                this.sitesIconHovered = true
            }
        },
        mouseLeave(menuName){
            if(menuName=='Recall Settings'){
                this.recallIconHovered = false
            } else if(menuName=='Advisory Settings'){
                this.disruptionIconHovered = false
            } else if(menuName=='Files'){
                this.filesIconHovered = false
            } else if(menuName=='Locations'){
                this.locationsIconHovered = false
            } else if(menuName=='Learn More'){
                this.learnMoreIconHovered = false
            } else if(menuName=='eR Responses'){
                this.erResponsesIconHovered = false
            } else if (menuName == 'Location Pre-Processor') {
                this.locationsPreProcessorIconHovered = false
            } else if (menuName == 'Quick Links') {
                this.quickLinksIconHovered = false
            } else if (menuName == 'Manage Supplier Master') {
                this.manageSupplierMasterIconHovered = false
            } else if (menuName == 'Sites') {
                this.sitesIconHovered = false
            }
        },
        hoverOn() {
            this.$refs.mainWrapper.classList.add('expand-menu')
        },
        hoverOff() {
            this.$refs.mainWrapper.classList.remove('expand-menu')
        },
        openAccountMenu() {
            this.$refs.accountMenu.show()
            this.$refs.accountBtn.classList.add('show')
        },
        clickOutsideAccountMenu() {
            this.$refs.accountBtn.classList.remove('show')
        },
        openAccountMenuMobile() {
            this.$refs.accountMenuMobile.show()
        },
        goToOrganizationGeneralSetting() {
            const _currentPath = this.$route && this.$route.name || ''
            if (this.org) {
                if (this.org.type === 'PROVIDER' && _currentPath !== paths.prov_Organization.name) {
                    this.$router.push({ name: paths.prov_Organization.name })
                } else if (this.org.type === 'SUPPLIER' && _currentPath !== paths.supp_Organization.name) {
                    this.$router.push({ name: paths.supp_Organization.name })
                }
            }
        },
        getAccountLinks() {
            if (this.$store != undefined && this.$store.state != undefined && this.$store.state.token != undefined
                && this.$store.state.token.access_token != undefined &&  this.$store.state.token.access_token != null
                 && this.$store.state.token.access_token!= '' ) {
                var access_token = getAccessToken()
                if (access_token) {
                    quickLinkService.getAccountLinks().then(res => {
                        if (!res.error) {
                            this.accountLinks = res.data.d
                        }
                    })
                }
            }
        },
        openLink(item){
            if (!item.url.match(/^https?:\/\//i)) {
                item.url = 'http://' + item.url
            }
            return window.open(item.url, '_blank')
        },
        openLinkNewWindow(item){

            if (!item.match(/^https?:\/\//i)) {
                item = 'http://' + item
            }
            return window.open(item, '_blank')
        }
    }
}