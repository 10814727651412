import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from './i18n'
import './components'
import './plugins'
import './filters'
import './rules'
import './mixins'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Clipboard from 'v-clipboard'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import  { LicenseManager } from 'ag-grid-enterprise'
import  'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.use(Clipboard)
library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Multiselect from 'vue-multiselect'

// register globally
Vue.component('multiselect', Multiselect)

window.moment = require('moment')
window._ = require('lodash')
LicenseManager.setLicenseKey('CompanyName=NotiSphere,LicensedGroup=Notisphere,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-031863,SupportServicesEnd=25_August_2023_[v2]_MTY5MjkxODAwMDAwMA==c1c2fd62c68db8a0aad7c3dc4d8e5db4')
Vue.use(require('vue-moment'))

Vue.config.productionTip = false

import { ValidationObserver, ValidationProvider } from 'vee-validate'
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)

import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
window.Apex.chart = { fontFamily: 'Rubik, sans-serif'}
import vuescroll from 'vuescroll'
import './registerServiceWorker'
Vue.use(vuescroll, {
    ops: {
        bar: {background: 'rgba(99, 99, 99, 0.2)'}
    }
})
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
