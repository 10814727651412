import { getToken, getOrgId, getUserType ,getRoleNameType,getAllSavedFilterConnections,getAllCustomizedHeaders} from '../misc/auth'

export default {
    status: '',
    token: getToken(),
    user: '',
    isAuth: false,
    inited: false,
    isNewAppVersionAvailable: false,
    isPermissionDenied: false,
    communicationId: '',
    providerCommunicationId: '',
    appVersionNumber: '',
    currentRoute: {},
    lastedRouteNotAuth: '',
    previousRoute: '/',
    authRoutes: [ '/login' ],
    meta: null,
    userType: getUserType(),
    orgId: getOrgId(),
    permissions: null,
    loading: false,
    selectUserTypes: null,
    organization: null,
    roleName: getRoleNameType(),
    AllPermissions: 0,
    SavedAllFilterConnections: getAllSavedFilterConnections(),
    CustomizedHeaders: getAllCustomizedHeaders()
}
