<template>
  <div class="ag-labeled ag-label-align-right ag-checkbox ag-input-field">
    <input style="width: 16px !important; height: 30px !important; margin-left: 9px !important;
    box-sizing: border-box !important;"  type="checkbox" id="headerSelectAllcheckbox" @click="checkAll($event)" v-model="checked"/>
  </div>
</template>
<script>
export default {
    data() {
        return {
            checked: false,
        }
    },
    methods: {
        checkAll(event) {
            this.checked = !this.checked
            if (this.params.column.colDef.HeaderChangeCallback) {
                this.params.column.colDef.HeaderChangeCallback(this.checked)
            }
        },
    },
}
</script>
<style lang="scss">
  .ag-row-selected::before {
    background-color: white !important;
  }
</style>