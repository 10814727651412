import Popper from 'popper.js'

export default {
    props: {
        value: { },
        options: { type: Array, default: [] },
        trackBy: { type: String, default: 'value' },
        labelBy: { type: String, default: 'text' },
        label: { type: String, default: '' },
        validatorName: { type: String, default: ''},
        returnObject: { type: Boolean, default: false },
        multiSelect: { type: Boolean, default: false },
        isRequired: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        hidelabel: { type: Boolean, default: false },
        disabled: {type: Boolean, default: false },
        tabindex: { type: String, default: '0' },
        hideCrossIcon: { type: Boolean, default: true },
        isSupplierFilter: { type: Boolean, default: false },
        isShowbydefaultBorder: { type: Boolean, default: false },
    },
    data () {
        return {
            popper: null,
            open: false,
            floatLabel: false,
            fsWidth: 0,
        }
    },
    created() {
        window.addEventListener('click', this.closeDropdown)
    },
    mounted() {
        this.popper = new Popper(this.$refs.theInput, this.$refs.dropdown, {
            positionFixed: false,
            removeOnDestroy: true,
            placement: 'bottom-start',
            modifiers: {
                computeStyle: {gpuAcceleration: false}
            },
            onUpdate: (data)=>{
                this.$refs.dropdown.style.width = data.offsets.reference.width + 'px'
            }
        })
        this.checkFocus(this.value)
    },
    beforeDestroy() {
        this.popper.destroy()
        window.removeEventListener('click', this.closeDropdown)
    },
    computed: {
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        },
        validateValue() {
            if (this.multiSelect) {
                return this.selectedOptions.length > 0 ? true : false
            } else {
                return this.selectedOption ? true : false
            }
        },
        selectedOptions: {
            get() {
                var multiVal = []
                if (this.multiSelect && this.value) {
                    this.options.forEach(x => {
                        if (this.value && this.value.includes(x[this.trackBy])) {
                            multiVal.push(x)
                        }
                    })
                }
                return multiVal
            },
            set(val) {
                if (this.multiSelect) {
                    if(this.returnObject){
                        this.$emit('input', val)
                    } else {
                        this.$emit('input', val.map(x => x[this.trackBy]))
                    }
                }
            }
        },
        selectedOption: {
            get() {
                var singleVal = null
                if (!this.multiSelect && this.value) {
                    if(this.returnObject && this.value[this.trackBy]) {
                        singleVal = this.options.find(x => x[this.trackBy] == this.value[this.trackBy])
                    } else {
                        singleVal = this.options.find(x => x[this.trackBy] == this.value)
                    }
                }
                return singleVal
            },
            set(val) {
                if (!this.multiSelect) {
                    if(this.returnObject){
                        this.$emit('input', val)
                    } else {
                        this.$emit('input', val[this.trackBy])
                    }
                }
            }
        }
    },
    watch: {
        value(v) {
            this.checkFocus(v)
        },
        open(newValue, oldValue) {
            if (!newValue && oldValue) {
                this.emitLostFocus()
            }
        }
    },
    methods: {
        remove() {
            this.$emit('input', null)
            this.$emit('update')
            this.$emit('lostfocus')
        },
        emitLostFocus() {
            if (this.selectedOptions.length > 0) {
                this.$emit('lostfocus')
            }
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : true
        },
        getClassIndicate(validationContext){
            if(validationContext.validated || validationContext.dirty){
                return this._getValidationState(validationContext) ? 'valid' : 'fail'
            } return ''
        },
        selectOption(item){
            if (this.multiSelect) {
                let _index = this.selectedOptions.findIndex(x => x[this.trackBy] === item[this.trackBy])
                var val = this.selectedOptions
                if(_index === -1) {
                    val.push(item)
                    this.selectedOptions = val
                } else {
                    val.splice(_index,1)
                    this.selectedOptions = val
                }
            } else {
                this.selectedOption = item
                this.open = false
            }
            this.$emit('update')
        },
        toggleSelectBox() {
            if (!this.disabled) {
                this.open = !this.open
                this.$refs.provider.setFlags({dirty: true})
                this.$nextTick(()=>{
                    this.popper.update()
                })
            }
        },
        checkSelected(item) {
            if (this.multiSelect && this.selectedOptions) {
                return this.selectedOptions.find(x => x.value === item.value) ? true : false
            }
        },

        //label
        checkFocus(val) {
            if((Array.isArray(val) && val.length == 0) || !val){
                this.$refs.wrapper.$el.classList.remove('onFocus')
                this.floatLabel = false
            } else {
                this.$refs.wrapper.$el.classList.add('onFocus')
                setTimeout(()=> {
                    this.fsWidth = this.$refs.theLabel.clientWidth
                }, 250)
                this.floatLabel = true
            }
        },
        hoverLabel() {
            this.$refs.theInput.classList.add('onHover')
        },
        leaveLabel() {
            this.$refs.theInput.classList.remove('onHover')
        },
        closeDropdown(e) {
            if (!this.$refs.select.contains(e.target)) {
                this.open = false
            }
        },
    }
}