<template>
  <b-modal
    :ref="modalRef"
    :no-stacking="noStacking"
    hide-footer
    hide-header
    :no-close-on-backdrop="noCloseOnBackdrop"
    :modal-class="modalClass"
    :size="size"
    centered
  >
    <div>
      <span @click="hideModal()" class="float-right custom-modal-close-button">
      </span>
    </div>
    <div style="margin-bottom: 1rem;" class="d-flex justify-content-between" v-if="!hideHeader">
      <span style="font-size: 1.3rem;" class="font-weight-bold">{{title}}</span>
    </div>
    <slot/>
  </b-modal>
</template>

<script>
export default {
    props: {
        modalRef: String,
        title: String,
        noStacking: {
            type: Boolean,
            default: false
        },
        modalClass: {
            type: String,
            default: 'custom-modal'
        },
        size: {
            type: String,
            default: 'md'
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {}
    },
    methods: {
        hideModal() {
            this.$refs[this.modalRef].hide()
        },
        show() {
            this.$refs[this.modalRef].show()
        }
    }
}
</script>