import {FILEPREVIEW } from '../../../../misc/commons'
export default {
    components: {
    },
    data() {
        return {
            url: require('@/assets/img/notisphere-blue.png'),
            item: null,
            admin: [],
            formType: 'INSERT',
            pre_signed_url: '',
        }
    },
    created() {
        this.item = null
    },
    computed: {
        fileType(){
            if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'image') {
                return 'image'
            } else
            if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'application' && this.item.mimetype.split('/')[1] == 'pdf') {
                this.pre_signed_url = this.item.signed_url_review
                return 'pdf'
            }
            else if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'application' && (this.item.mimetype.split('/')[1] == FILEPREVIEW.SPREADSHEET || this.item.mimetype.split('/')[1] == 'vnd.ms-excel')) {
                this.pre_signed_url = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url)
                return 'xlsx'
            }
            else if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'text' && this.item.mimetype.split('/')[1] == 'csv') {
                this.pre_signed_url = this.item.signed_url
                return 'csv'
            }
            else if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'text' && this.item.mimetype.split('/')[1] == 'plain') {
                this.pre_signed_url = this.item.signed_url
                return 'text'
            }
            else if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'application' && (this.item.mimetype.split('/')[1] == 'msword' || this.item.mimetype.split('/')[1] == FILEPREVIEW.DOCUMENT)) {
                this.pre_signed_url = FILEPREVIEW.URL + encodeURIComponent((this.item.signed_url))
                return 'doc'
            }
            else if (this.item.mimetype && this.item.mimetype.split('/')[0] == 'application' && (this.item.mimetype.split('/')[1] == FILEPREVIEW.PPT || this.item.mimetype.split('/')[1] == FILEPREVIEW.DOCUMENT)) {
                this.pre_signed_url = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url)
                return 'ppt'
            }
            else {
                return null
            }
        }
    },
    methods: {
        download() {
            if (this.item.signed_url) {
                this.openExternalLink(this.item.signed_url)
            } else {
                this._showToast('Nothing to download', {variant: 'danger'})
            }
        },

        forceRerenderInsert(val) {
            this.formType = 'INSERT'
            this.item = val
            this.$refs.modal.show()
        },
        cancel() {
            this.$refs.modal.hide()
        }
    }
}