import { http, param } from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'

export const API_ROOT = `${conf.API_URL}`

const SupplierRecallService = {
    getRecallMetaData () {
        return http.get(`${API_ROOT}/supplier/communications/meta`).then(resp => {
            return resp
        })
    },

    communicationDetail (id) {
        return http.get(`${API_ROOT}/supplier/communications/${id}`).then(resp => {
            return resp
        })
    },

    saveCommunicationStep0Info (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step0`, payload).then(resp => {
            return resp
        })
    },

    saveCommunicationStep1Products (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step1`, payload).then(resp => {
            return resp
        })
    },
    readProductFileAtStep1Products (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step1/read-product-file`, payload).then(resp => {
            return resp
        })
    },

    addNewProductsAtStep1Products (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step1/add-new-products`, payload).then(resp => {
            return resp
        })
    },

    saveCommunicationStep2Actions (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step2`, payload).then(resp => {
            return resp
        })
    },

    saveCommunicationStep3Locations (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step3`, payload).then(resp => {
            return resp
        })
    },
    async saveCommunicationStep3Customers (payload) {
        return http.post(`${API_ROOT}/supplier/communications/step3/customers`, payload).then(resp => {
            return resp
        })
    },
    saveRecallChangeHistory(payload) {
        return http.post(`${API_ROOT}/supplier/communications/change-history`, payload).then(resp => {
            return resp
        })
    },

    async findCommunications(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationType', val: payload.communicationType },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/supplier/communications${param(params)}`).then(resp => {
            return resp
        })
    },
    async findProCommunications(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationType', val: payload.communicationType },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/provider/communications${param(params)}`).then(resp => {
            return resp
        })
    },
    downloadRecallSummaryReport(payload) {
        return http.get(`${API_ROOT}/supplier/dashboard/${payload.status}/actions/supplier/summeryreport`,{ responseType: 'blob' }).then(resp => {

            fileDownload(resp.data, 'Recall Events Summary Report.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    downloadSupplierSummaryCSVReport(payload) {
        return http.get(`${API_ROOT}/supplier/dashboard/${payload.status}/actions/supplier/summeryreport/csv`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Recall Events Summary Report.csv','text/csv')
        })
    },
    async downloadChangeHistoryXLSXReport(communicationId,communicationUID) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/change-history-download`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Change History Report '+communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    findLocationsBycommunicationIdAndFileId(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationId', val: payload.communicationId },
            { query: 'fileId', val: payload.fileId },
            { query: 'type', val: payload.type },
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/locations-by-file${param(params)}`).then(resp => {
            return resp
        })
    },
    updateLocationStatus(communicationId, isConfirm) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/update-status/${isConfirm}`).then(resp => {
            return resp
        })
    },
    removeAffectedLocations(communicationId, locationIds) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/remove-locations`, locationIds).then(resp => {
            return resp
        })
    },
    async locationsBycommunicationId(payload) {
        let params = [
            { query: 'communicationId', val: payload.communicationId }
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/locations-all${param(params)}`).then(resp => {
            return resp
        })
    },
    async findAffectedLocationsBycommunicationIdAndCoveredStatus(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'locationId', val: payload.locationid ? payload.locationid.trim() : '' },
            { query: 'locationName', val: payload.locationname ? payload.locationname.trim() : '' },
            { query: 'idn', val: payload.idn ? payload.idn.trim() : '' },
            { query: 'address1', val: payload.address1 ? payload.address1.trim() : '' },
            { query: 'address2', val: payload.address2 ? payload.address2.trim() : '' },
            { query: 'city', val: payload.city ? payload.city.trim() : '' },
            { query: 'state', val: payload.state ? payload.state.trim() : '' },
            { query: 'zip', val: payload.zip ? payload.zip.trim() : '' },
            { query: 'status', val: payload.status ? (payload.status.trim() == 'Unrecognized' ? 'UNABLE_PROCESSED' : (payload.status.trim() == 'Not Covered' ? 'NON_COVERED' : payload.status.trim())) : '' },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationId', val: payload.communicationId },
            { query: 'type', val: payload.type },
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/locations${param(params)}`).then(resp => {
            return resp
        })
    },
    async findSupplierAffectedCustomersBycommunicationId(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationId', val: payload.communicationId },
            { query: 'type', val: payload.type },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/customers${param(params)}`).then(resp => {
            return resp
        })
    },

    async findSupplierSalesRepsBycommunicationId(payload) {
        let params = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationId', val: payload.communicationId },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/salesreps${param(params)}`).then(resp => {
            return resp
        })
    },

    downloadAffectedLocations(payload) {
        let params = [
            { query: 'fileId', val: payload.fileId },
            { query: 'type', val: payload.type },
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/locations-csv-download${param(params)}`).then(resp => {
            return resp
        })
    },
    downloadFDAReportFile(payload) {
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/fdareport`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'FDA Effectiveness Report '+ payload.communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    downloadAffectedLocationsXlxs(payload,communicationId) {
        let params = [
            { query: 'fileId', val: payload.fileId },
            { query: 'type', val: payload.type },
        ]
        return http.get(`${API_ROOT}/supplier/communications/${payload.communicationId}/locations-xlxs-download${param(params)}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, payload.type==='UNABLE_PROCESSED'?'Affected Locations Unrecognized ' +communicationId+'.xlsx' : (payload.type==='NON_COVERED'?'Affected Locations Not Covered ' +communicationId+'.xlsx' :(payload.type==='EMAILED'? 'Affected Locations Emailed ' +communicationId+'.xlsx' :'Affected Locations Covered ' +communicationId+'.xlsx')),'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            return resp
        })
    },

    downloadProductTemplate(id) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/product-template`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data,'ProductTemplate.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },

    //Recall View
    getCommunicationSummaryById(communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/summary`).then(resp => {
            return resp
        })
    },
    getRecallById(communicationId,communicationType) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/view/${communicationType}`).then(resp => {
            return resp
        })
    },

    getDocumentById(docId) {
        return http.get(`${API_ROOT}/supplier/communications/document/${docId}`).then(resp => {
            return resp
        })
    },
    downloadproviderstausReport(id, communicationUID) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/statusreport`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Provider Status Report_'+communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    exportproviderLocationstausReport(id, communicationUID){
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/recalleventstatusreport/csv`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Recall Event Status by Location Report '+communicationUID+'.csv','text/csv')
        })
    },
    exportproviderstausReport(id, communicationUID) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/statusreport/csv`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Provider Status Report_'+communicationUID+'.csv','text/csv')
        })
    },
    downloadproviderLocationstausReport(id, communicationUID) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/eventstatusbylocation`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Recall Events Status by Location Report '+communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    downloadSummaryReport(id) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/summeryreport`,{ responseType: 'blob' }).then(resp => {

            fileDownload(resp.data, resp.msg,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    //Recall View - Conversation
    getRecallConversations (communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/conversations`).then(resp => {
            return resp
        })
    },
    createRecallConversation (communicationId,providercommunicationId,ProviderId,communicationType, payload) {
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/conversations/${ProviderId}/${providercommunicationId}/${communicationType}`, payload).then(resp => {
            return resp
        })
    },

    updateRecallConversation (communicationId, payload) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/conversations/${payload.id}`, payload).then(resp => {
            return resp
        })
    },
    //Recall View - Conversation - Message
    getRecallConversation (communicationId, conversationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/conversations/${conversationId}`).then(resp => {
            return resp
        })
    },
    createRecallConversationMessage (communicationId, conversationId, formData) {
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/conversations/${conversationId}/message`, formData).then(resp => {
            return resp
        })
    },
    getRecallConversationMessageFile (communicationId, conversationId, fileId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/conversations/${conversationId}/file/${fileId}`).then(resp => {
            return resp
        })
    },

    //Recall View - Actions
    GetRecallAction (communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/actions`).then(resp => {
            return resp
        })
    },
    GetRecallAction_Detail (communicationId, actionId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/actions/${actionId}`).then(resp => {
            return resp
        })
    },
    GetRecallAction_ProviderListing (communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/actions/provider`).then(resp => {
            return resp
        })
    },
    GetRecallAction_ProviderListing_Detail (communicationId, providerId,providercommunicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/actions/provider/${providerId}/${providercommunicationId}`).then(resp => {
            return resp
        })
    },
    async GetCustomerAction (communicationId, providerId,providercommunicationId, emailAddress) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/actions/provider/${providerId}/${providercommunicationId}/${emailAddress}`).then(resp => {
            return resp
        })
    },
    getFileById (fileId) {
        return http.get(`${API_ROOT}/supplier/communications/file/${fileId}`).then(resp => {
            return resp
        })
    },
    async getPaperFileById (fileId) {
        return http.get(`${API_ROOT}/supplier/communications/file/paper/${fileId}`).then(resp => {
            return resp
        })
    },
    AddReportDownloadHistory (communicationId,selectedReport) {
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/add-report-history/${selectedReport}`).then(resp => {
            return resp
        })
    },
    GetReportHistories (communicationId,selectedReport) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/report-history/${selectedReport}`).then(resp => {
            return resp
        })
    },
    GetRecallHistories (communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/histories`).then(resp => {
            return resp
        })
    },
    closeOpenCommunication (communicationId) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/close-open`).then(resp => {
            return resp
        })
    },
    deleteCommunication (communicationId,communicationType) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/delete/${communicationType}`).then(resp => {
            return resp
        })
    },
    approveRecall(communicationId, communicationType, userName) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/approve-recall/${communicationType}/${userName}`).then(resp => {
            return resp
        })
    },
    sendNotification (communicationId, sendType,communicationType,notificationDate,selectedNonCoveredSendNotification) {
        return http.put(`${API_ROOT}/supplier/communications/${communicationId}/send-notification/${communicationType}/?sendType=${sendType}&notificationDate=${notificationDate}&senTypeNotCov=${selectedNonCoveredSendNotification}`).then(resp => {
            return resp
        })
    },
    updateDisruption(communicationId,formData){
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/postupdate`,formData).then(resp => {
            return resp
        })
    },

    getAuditReportById(id) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/Audit`).then(resp => {
            return resp
        })
    },
    downloadAuditReport(id, communicationUID) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/auditreport`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Notification Report_'+communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    exportAuditReport(id, communicationUID) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/actions/provider/auditreport/csv`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Notification Report_'+communicationUID+'.csv','text/csv')
        })
    },
    async getSupplierPaperResponse(payload,id) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
        ]
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/list${param(arr)}`).then(resp => {
            return resp
        })
    },
    async getProviderNamesForPaperResponse(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/providernames`).then(resp => {
            return resp
        })
    },
    async getLocationForPaperResponse(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/locationnames`).then(resp => {
            return resp
        })
    },
    async getLocationForEditPaperResponse(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/editlocationnames`).then(resp => {
            return resp
        })
    },
    async getNotifiedDateForPaperResponse(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/notifieddate`).then(resp => {
            return resp
        })
    },
    async getRecallActionsForPaperResponse(payload) {
        return http.post(`${API_ROOT}/supplier/communications/paper/supplieractions`,payload).then(resp => {
            return resp
        })
    },
    async getDocForPaperResponse(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/doc`).then(resp => {
            return resp
        })
    },
    async deletePaperResponse(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/delete`).then(resp => {
            return resp
        })
    },
    async getPaperResponseActions(id) {
        return http.get(`${API_ROOT}/supplier/communications/paper/${id}/getactions`).then(resp => {
            return resp
        })
    },
    async createPaperResponse (payload) {
        return http.post(`${API_ROOT}/supplier/communications/paper`, payload).then(resp => {
            return resp
        })
    },
    async updatePaperResponse (payload) {
        return http.post(`${API_ROOT}/supplier/communications/paper/update`, payload).then(resp => {
            return resp
        })
    },
    async downloadPaperResponseTemplate(id,communicationUID) {
        const resp = await http.get(`${API_ROOT}/supplier/communications/${id}/paper-response-template`, { responseType: 'blob' })
        fileDownload(resp.data, `Paper Response Template_${communicationUID}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    },

    async importPaperResponse (communicationId,formData) {
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/paper-response-import`, formData).then(resp => {
            return resp
        })
    },
}

export default SupplierRecallService
