<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0823 14.7169C15.7357 13.6171 16.9951 10.5745 15.8952 7.92113C14.7954 5.26777 11.7529 4.00838 9.0995 5.1082C6.44614 6.20803 5.18675 9.25058 6.28657 11.9039C7.3864 14.5573 10.429 15.8167 13.0823 14.7169Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M17.4782 4.94911C18.4641 4.94911 19.2662 4.14702 19.2662 3.16108C19.2662 2.17514 18.4641 1.37305 17.4782 1.37305C16.4923 1.37305 15.6902 2.17514 15.6902 3.16108C15.6902 4.14702 16.4923 4.94911 17.4782 4.94911Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M5.84872 2.98822C7.36597 1.83854 9.17714 1.23084 11.0864 1.23084C12.4674 1.23084 13.7877 1.5456 15.0106 2.16639L15.5678 1.06886C14.1706 0.359609 12.6628 0 11.0864 0C8.90652 0 6.83835 0.694068 5.10538 2.0072C3.42918 3.27734 2.17796 5.08154 1.58228 7.08752L2.76224 7.4379C3.28378 5.68141 4.37992 4.10118 5.84872 2.98822Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M19.6713 4.95312L18.6061 5.56981C19.3669 6.88402 19.7691 8.38592 19.7691 9.91323C19.7691 11.8225 19.1614 13.6336 18.0117 15.1509C16.8987 16.6197 15.3185 17.7159 13.562 18.2374L13.9123 19.4174C15.9184 18.8217 17.7226 17.5705 18.9927 15.8943C20.3058 14.1613 20.9999 12.0931 20.9999 9.91323C20.9999 8.16967 20.5404 6.4545 19.6713 4.95312Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M2.76219 12.3906L1.58228 12.741C2.04893 14.3125 2.9115 15.7591 4.07681 16.9244C5.24208 18.0897 6.68868 18.9523 8.26021 19.4189L8.61059 18.239C7.23485 17.8305 5.96803 17.0749 4.94713 16.054C3.92628 15.0331 3.17071 13.7664 2.76219 12.3906Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M11.0863 17.4238C10.1004 17.4238 9.29834 18.2259 9.29834 19.2119C9.29834 20.1978 10.1004 20.9999 11.0863 20.9999C12.0722 20.9999 12.8744 20.1978 12.8744 19.2119C12.8744 18.2259 12.0722 17.4238 11.0863 17.4238Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M1.78803 11.7011C2.77554 11.7011 3.57607 10.9005 3.57607 9.91303C3.57607 8.92553 2.77554 8.125 1.78803 8.125C0.80053 8.125 0 8.92553 0 9.91303C0 10.9005 0.80053 11.7011 1.78803 11.7011Z"
      :fill="hovered ? hoverColor : fillColor" />
  </svg>

</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>