import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'
export const API_ROOT = `${conf.API_URL}`
const delegateService={
    getDelegateById(id) {
        return http.get(`${API_ROOT}/delegate/${id}`).then(resp => {
            return resp
        })
    },
    updateDelegate( payload,communicationType) {
        return http.put(`${API_ROOT}/delegate/update-delegate/${communicationType}`, payload).then(resp => {
            return resp
        })
    },
    getRecallById(communicationId,organizationId, delegateId,providerRecallId) {
        return http.get(`${API_ROOT}/delegate/${communicationId}/${organizationId}/${delegateId}/${providerRecallId}`).then(resp => {
            return resp
        })
    },
    async getSupplierRecallById(id,organizationId, delegateId) {
        return http.get(`${API_ROOT}/delegate/${id}/${organizationId}/${delegateId}`).then(resp => {
            return resp
        })
    },
    getRecallResponseById(id,organizationId, delegateId,providerRecallId) {
        return http.get(`${API_ROOT}/delegate/response/${id}/${organizationId}/${delegateId}/${providerRecallId}`).then(resp => {
            return resp
        })
    },
    getDocumentById(docId, organizationId) {
        return http.get(`${API_ROOT}/delegate/document/${docId}/${organizationId}`).then(resp => {
            return resp
        })
    },
    getDelegateDocumentById(docId, organizationId,delegateId) {
        return http.get(`${API_ROOT}/delegate/${delegateId}/delegatedocument/${docId}/${organizationId}`).then(resp => {
            return resp
        })
    },
    getRecallActions(communicationId,organizationId) {
        return http.get(`${API_ROOT}/delegate/${communicationId}/actions/${organizationId}`).then(resp => {
            return resp
        })
    },
    getRecallActionFilesAsync (communicationId, actionId, actionType,organizationId,delegateId) {
        return http.get(`${API_ROOT}/delegate/${communicationId}/actions/${actionId}/file/${organizationId}/${delegateId}?actionType=${actionType}`).then(resp => {
            return resp
        })
    },
    updateRecallActions(communicationId,communicationType,organizationId,delegateId, payload) {
        return http.put(`${API_ROOT}/delegate/${communicationId}/${communicationType}/actions/${organizationId}/${delegateId}`, payload).then(resp => {
            return resp
        })
    },
    uploadRecallActionFile (formData, communicationId, communicationType, actionId,organizationId,delegateId) {
        return http.post(`${API_ROOT}/delegate/${communicationId}/${communicationType}/actions/${actionId}/file/${organizationId}/${delegateId}`, formData).then(resp => {
            return resp
        })
    },
    async uploadPaperRecallActionFile (formData, paperId, actionId) {
        return http.post(`${API_ROOT}/supplier/communications/${paperId}/actions/${actionId}/file`, formData).then(resp => {
            return resp
        })
    },
    deleteRecallActionFileAsync (communicationId, communicationType, actionId, fileId,organizationId,delegateId) {
        return http.delete(`${API_ROOT}/delegate/${communicationId}/${communicationType}/actions/${actionId}/file/${fileId}/${organizationId}/${delegateId}`).then(resp => {
            return resp
        })
    },
    getFileById (fileId,organizationId) {
        return http.get(`${API_ROOT}/delegate/file/${fileId}/${organizationId}`).then(resp => {
            return resp
        })
    },
    StaticFile (extension, download) {
        return http.get(`${API_ROOT}/delegate/static-documents/${extension}/${download}`).then(resp => {
            return resp
        })
    },
}
export default delegateService