/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.VUE_APP_BASE_URL}/service-worker.js`, {
        ready () {
            console.log(
                'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered (registration) {
            console.log('Service worker has been registered.')
            registration.update()
        },
        cached () {
            console.log('Content has been cached for offline use.')
        },
        updatefound () {
            console.log('New content is downloading.')
        },
        updated () {
            console.log('New content is available; please refresh.')
            if(this.$store && this.$store.state){
                this.$store.state.isNewAppVersionAvailable= false
                localStorage.setItem('app-version', this.$store.state.appVersionNumber)
                window.location.reload(true) // For new version, simply reload on any get
            }
        },
        offline () {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
