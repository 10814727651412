<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M3.16667 5.34375C3.16667 3.27038 4.85371 1.58333 6.92708 1.58333H14.1574C13.8969 0.672125 13.0665 0 12.0729 0H2.96875C1.76779 0 0.791666 0.976125 0.791666 2.17708V14.4479C0.791666 15.6489 1.76779 16.625 2.96875 16.625H3.16667V5.34375Z"
        :fill="hovered ? hoverColor : fillColor" />
      <path
        d="M16.0312 3.16666H6.92708C5.72612 3.16666 4.75 4.14278 4.75 5.34374V16.8229C4.75 18.0239 5.72612 19 6.92708 19H16.0312C17.2322 19 18.2083 18.0239 18.2083 16.8229V5.34374C18.2083 4.14278 17.2322 3.16666 16.0312 3.16666ZM14.4479 16.625H8.51042C8.18267 16.625 7.91667 16.359 7.91667 16.0312C7.91667 15.7035 8.18267 15.4375 8.51042 15.4375H14.4479C14.7757 15.4375 15.0417 15.7035 15.0417 16.0312C15.0417 16.359 14.7757 16.625 14.4479 16.625ZM14.4479 13.4583H8.51042C8.18267 13.4583 7.91667 13.1923 7.91667 12.8646C7.91667 12.5368 8.18267 12.2708 8.51042 12.2708H14.4479C14.7757 12.2708 15.0417 12.5368 15.0417 12.8646C15.0417 13.1923 14.7757 13.4583 14.4479 13.4583ZM14.4479 10.6875H8.51042C8.18267 10.6875 7.91667 10.4215 7.91667 10.0937C7.91667 9.76599 8.18267 9.49999 8.51042 9.49999H14.4479C14.7757 9.49999 15.0417 9.76599 15.0417 10.0937C15.0417 10.4215 14.7757 10.6875 14.4479 10.6875ZM14.4479 7.52082H8.51042C8.18267 7.52082 7.91667 7.25482 7.91667 6.92707C7.91667 6.59932 8.18267 6.33332 8.51042 6.33332H14.4479C14.7757 6.33332 15.0417 6.59932 15.0417 6.92707C15.0417 7.25482 14.7757 7.52082 14.4479 7.52082Z"
        :fill="hovered ? hoverColor : fillColor" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>


</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>