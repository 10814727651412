//rules
import { extend } from 'vee-validate'
import { confirmed, required, email, min } from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'
import i18n from '@/i18n'

extend('phoneNumber', {
    message: () => i18n.t('Error.invalid_phone_num'),
    validate (value) {
        return new Promise(resolve => {
            var strongRegex = new RegExp(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)
            var phoneReg = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/
	        var zerosReg = /[1-9]/g
            if(!value.match(phoneReg) || !zerosReg.test(value))
            {
                resolve({})
            }
            else
            {
                resolve({ valid: strongRegex.test(value) })
            }
        })
    }
})
extend('verify_password', {
    message: () => 'Minimum 8 characters, must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (!@#$%^&*)',
    validate (value) {
        var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
        return strongRegex.test(value)
    }
})

extend('domain', {
    message: () => 'Invalid domain name',
    validate (value) {
        var strongRegex = new RegExp(/^[a-z0-9]{0,61}[a-z0-9-]{0,61}[a-z0-9](?:\.[a-z0-9]{2,})+$/g)
        return strongRegex.test(value)
    }
})

extend('confirmed', {
    ...confirmed,
    message: () => i18n.t('Error.password_not_match')
})

extend('required', {
    ...required,
    message: () => i18n.t('Error.field_required')
})

extend('required_editor', {
    ...required,
    message: () => i18n.t('Error.field_required'),
    validate (value) {
    // allow spaces, but check if it's non-empty after trimming spaces
        if(value != undefined)
        {
            // Create a temporary HTML element (a div)
            const bodyDiv = document.createElement('div')
            // Set the innerHTML of the div to the HTML string
            bodyDiv.innerHTML = value
            // Extract text content from the div
            this.extractedText = bodyDiv.textContent
            if(this.extractedText.trim() != '')
            {
                return true
                //const specialCharacterPattern = /[^a-zA-Z0-9 ]/g
                // Use the replace method to remove special characters from the input
                //const alphanumericPattern = /^[a-zA-Z0-9]*$/
                // if(this.extractedText.trim() != '')
                // {
                //     return alphanumericPattern.test(this.extractedText.replace(specialCharacterPattern, ''))
                // }
                // else
                // {
                //     return false
                // }
            }
            else
            {
                return false
            }
        }
        else
        {
            return true
        }
    }
})
// alternative conversation
extend('conversation_error',
    {
        ...required,
        message: ()=> i18n.t('Field required')
    })
//end here
extend('notrequired', {
    message: () => i18n.t(''),
    validate () {

        return true
    }
})
extend('requiredSelect', {
    message: () => i18n.t('Error.field_required'),
    validate (value) {
        return value
    }
})

extend('email', {
    ...email,
    message: () => i18n.t('Error.email_not_valid')
})
extend('erEmail', {
    ...email,
    message: () => i18n.t('Error.email_not_valid'),
    validate (value) {
        var EMAIL_REGEX = new RegExp(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)
        var strongRegex = new RegExp(/^[0-9a-zA-Z/\\/+()_-]+$/)
        return EMAIL_REGEX.test(value.trim())
    }
})

extend('url', {
    message: () => i18n.t('Error.url_not_valid'),
    validate (value) {
        var strongRegex = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]&;%@!\\$&\\\'\\(\\)\\*\\+,;=.]+$', 'i')
        //var strongRegex = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\%\\$&\\\'\\(\\)\\*\\+,;=.]+$', 'i')
        return strongRegex.test(value)
    }
})
extend('min', {
    ...min,
    message: (val, args) => {
        return i18n.t('Error.min_char', {msg: args.length})
    }
})

extend('alphanumeric', {
    message: () => 'Only Alphanumeric and special characters like \\+/-_)([]:{}<>. are accepted',
    validate (value) {
        var strongRegex = new RegExp(/^[0-9a-zA-Z\s/\\/+()_-{}[\]<>.:-]+$/)
        return strongRegex.test(value)
    }
})


extend('numeric', {
    message: () => 'Only numeric values are accepted',
    validate (value) {
        var strongRegex =new RegExp('^[0-9]*$')
        return strongRegex.test(value)
    }
})