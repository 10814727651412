import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`


const quickLinkService = {
    findQuickLink(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'status', val: payload.status },
            { query: 'url', val: payload.supplierName },
        ]
        return http.get(`${API_ROOT}/notisphere/quickLinks${param(arr)}`).then(resp => {
            return resp
        })
    },
    findMeta(data) {
        return http.get(`${API_ROOT}/notisphere/quickLinks/meta`).then(resp => {
            return resp
        })
    },
    getAccountLinks() {
        return http.get(`${API_ROOT}/notisphere/quickLinks/account-links`).then(resp => {
            return resp
        })
    },


    getquicklinksbyId(id) {
        return http.get(`${API_ROOT}/notisphere/quickLinks/${id}`).then(resp => {
            return resp
        })
    },
    create_QuickLink(data) {

        return http.post(`${API_ROOT}/notisphere/quickLinks/create`, data).then(resp => {
            return resp
        })
    },

    getQuickLinkData(payload) {
        return http.get(`${API_ROOT}/notisphere/quickLinks/${payload.administerlinkId}`).then(resp => {
            return resp
        })
    },
    deleteQuickLink(administerId) {
        return http.put(`${API_ROOT}/notisphere/quickLinks/delete/${administerId}`).then(resp => {
            return resp
        })
    },
}

export default quickLinkService