import { http, param } from '../../utils/http'
import conf from '../../config'
export const API_ROOT = `${conf.API_URL}`

const communicationNoticeService =
{
    communicationNotice(providercommunicationId,communicationType,email) {
        return http.get(`${API_ROOT}/provider/communication-notices/${providercommunicationId}/?communicationType=${communicationType}&email=${email}`).then(resp => {
            return resp
        })
    },
    updateCommunicationNotice(payload) {
        return http.put(`${API_ROOT}/provider/communication-notices`, payload).then(resp => {
            return resp
        })
    },
    communication(organizationId, recallId, providercommunicationId) {
        return http.get(`${API_ROOT}/provider/communication-notices/${organizationId}/${recallId}/${providercommunicationId}`).then(resp => {
            return resp
        })
    },
    communicationForPd(organizationId, recallId, providercommunicationId,communicationAffectedCustomerId) {
        return http.get(`${API_ROOT}/provider/communication-notices/${organizationId}/${recallId}/${providercommunicationId}/${communicationAffectedCustomerId}`).then(resp => {
            return resp
        })
    },
    acknowledgeCommunication(organizationId, recallId, providercommunicationId, email) {
        return http.put(`${API_ROOT}/provider/communication-notices/acknowledge/${organizationId}/${recallId}/${providercommunicationId}/${email}`).then(resp => {
            return resp
        })
    },
    file(organizationId, fileId) {
        return http.get(`${API_ROOT}/provider/communication-notices/${organizationId}/file/${fileId}`).then(resp => {
            return resp
        })
    },
    document(organizationId, documentId) {
        return http.get(`${API_ROOT}/provider/communication-notices/${organizationId}/document/${documentId}`).then(resp => {
            return resp
        })
    },
    updateCommunicationActions(organizationId, recallId, communicationType, payload, providercommunicationId, communicationAffectedCustomerId) {
        return http.put(`${API_ROOT}/provider/communication-notices/${organizationId}/${recallId}/actions/${providercommunicationId}/?communicationType=${communicationType}&communicationAffectedCustomerId=${communicationAffectedCustomerId}`, payload).then(resp => {
            return resp
        })
    },
    communicationHistories(organizationId, recallId, providercommunicationId) {
        return http.get(`${API_ROOT}/provider/communication-notices/${organizationId}/${recallId}/histories/${providercommunicationId}`).then(resp => {
            return resp
        })
    },
    communicationActionFiles(organizationId, recallId, actionId, actionType, providercommunicationId, communicationAffectedCustomerId) {
        return http.get(`${API_ROOT}/provider/communication-notices/${organizationId}/${recallId}/actions/${actionId}/file/${providercommunicationId}/?actionType=${actionType}&communicationAffectedCustomerId=${communicationAffectedCustomerId}`).then(resp => {
            return resp
        })
    },
    uploadCommunicationActionFile(formData, organizationId, recallId, communicationType, actionId, providercommunicationId,email) {
        return http.post(`${API_ROOT}/provider/communication-notices/${organizationId}/${recallId}/actions/${actionId}/file/${providercommunicationId}/?communicationType=${communicationType}&email=${email}`, formData).then(resp => {
            return resp
        })
    },
    deleteCommunicationActionFile(organizationId, communicationId, communicationType, actionId, fileId) {
        return http.delete(`${API_ROOT}/provider/communication-notices/${organizationId}/${communicationId}/actions/${actionId}/file/${fileId}/?communicationType=${communicationType}`).then(resp => {
            return resp
        })
    },
}

export default communicationNoticeService