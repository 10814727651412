import { VueEditor } from 'vue2-editor'

export default {
    components: { VueEditor },
    props: {
        content: { type: String, default: ''},
        label: { type: String, default: ''},
        placeholder: { type: String, default: ''},
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        isRequired: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false},
        hideToolBar: { type: Boolean, default: false},
        actioneditor: { type: Boolean, default: false},
        contentManagement: { type: Boolean, default: false},
        actionDisableEditor: { type: Boolean, default: false},
    },
    data(){
        return {
            customToolbar: [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
            ],
            customDisableToolbar: [
                []
            ],
            customActionToolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
            ],
            editorOptions: {
                modules: {
                    toolbar: true,
                },
            },
            editorOptionsnew: {
                modules: {
                    toolbar: false,
                },
            }
        }
    },
    mounted() {
    },
    created() {
        if(this.hideToolBar)
        {
            this.editorOptions.modules.toolbar=false
        }

    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        editorContent: {
            get() {
                return this.content },
            set(updatedContent) { this.$emit('update:content', updatedContent) }
        },
    },
    watch: {
    },
    methods: {
        getClassIndicate(validationContext){
            if(validationContext.validated || validationContext.dirty){
                return this._getValidationState(validationContext) ? 'valid' : 'fail'
            } return ''
        },
    }
}