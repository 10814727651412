<template>
  <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.45559 5.62601C6.7493 5.62601 5.36108 7.01418 5.36108 8.72047C5.36108 10.4268 6.7493 11.815 8.45559 11.815C10.1619 11.815 11.5501 10.4268 11.5501 8.72047C11.5501 7.01418 10.1619 5.62601 8.45559 5.62601ZM8.45563 10.5776C7.43164 10.5776 6.59853 9.74447 6.59853 8.72047C6.59853 7.69648 7.43164 6.86341 8.45563 6.86341C9.47963 6.86341 10.3127 7.69648 10.3127 8.72047C10.3127 9.74447 9.47963 10.5776 8.45563 10.5776Z"
      :fill="hovered ? hoverColor : fillColor"  />
    <path
      d="M8.45562 0C3.79321 0 0 3.79317 0 8.45558C0 10.1042 0.435135 11.7843 1.29326 13.4493C1.9692 14.7607 2.90829 16.066 4.08442 17.3287C6.07404 19.4649 8.03931 20.7333 8.122 20.7863L8.45566 21L8.78932 20.7863C8.87201 20.7334 10.8372 19.4649 12.8269 17.3287C14.003 16.066 14.942 14.7607 15.618 13.4493C16.4762 11.7843 16.9113 10.1042 16.9113 8.45558C16.9112 3.79317 13.1181 0 8.45562 0ZM14.0254 9.33926H12.7431C12.6379 10.0722 12.3487 10.7465 11.9236 11.3136L12.8315 12.2215L11.9565 13.0965L11.0486 12.1886C10.4815 12.6136 9.80721 12.9028 9.0743 13.0081V14.2904H7.8369V13.0081C7.10399 12.9028 6.42965 12.6136 5.86257 12.1885L4.95465 13.0965L4.07966 12.2215L4.98758 11.3135C4.5625 10.7465 4.27334 10.0721 4.16805 9.33918H2.88578V8.10178H4.16805C4.27329 7.36883 4.5625 6.69453 4.98758 6.12745L4.07966 5.21953L4.95465 4.34454L5.86257 5.25246C6.42965 4.82742 7.10399 4.53821 7.83694 4.43293V3.1507H9.07434V4.43297C9.80729 4.53821 10.4816 4.82742 11.0487 5.2525L11.9566 4.34458L12.8316 5.21957L11.9237 6.12749C12.3487 6.69457 12.6379 7.36891 12.7432 8.10186H14.0254V9.33926Z"
      :fill="hovered ? hoverColor : fillColor"  />
  </svg>
</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>